import DataTileSource from 'ol/source/DataTile.js';
import { fromBlob } from 'geotiff';
import { toast } from 'react-toastify';

const getWcsTileLoader =
  (baseUrl, projection, time, layerName, tileGrid) => async (z, x, y) => {
    const tileSizePixels = tileGrid.getTileSize(z);
    const [minx, miny, maxx, maxy] = tileGrid.getTileCoordExtent([z, x, y]);
    let toastId;

    const url_ = `${baseUrl}?service=WCS&version=1.0.0&request=GetCoverage&coverage=${layerName}&format=GeoTIFF&bbox=${minx},${miny},${maxx},${maxy}&crs=${projection?.getCode()}&width=${tileSizePixels}&height=${tileSizePixels}&time=${time}`;

    try {
      toast.dismiss(toastId);
      const res = await fetch(url_, { signal: AbortSignal.timeout(25000) });
      const blob = await res.blob();
      const tiff = await fromBlob(blob);
      const arr = await tiff.readRasters();
      return new Float32Array(...arr);
    } catch (error) {
      if (error.name == 'TimeoutError') {
        toast.error('Error reaching WCS. Please try again later', {
          toastId: 1,
          autoClose: false,
        });
      }
    }
  };

export const useWCS1 = ({
  baseUrl = '',
  layerName = '',
  timeISO = '',
  projection,
  tileGrid,
  attributions,
}) => {
  const dt = new DataTileSource({
    projection,
    tileGrid,
    loader: getWcsTileLoader(baseUrl, projection, timeISO, layerName, tileGrid),
    bandCount: 1,
    attributions,
  });

  return dt;
};
